<template>
  <div>
    <el-form
      label-position="top"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      class="form"
      ref="form"
      v-if="model"
      :disabled="isDisabledInternal"
    >
      <el-row type="flex" :gutter="20">
        <el-col :span="4">
          <el-form-item
            :label="fields.mandant.label"
            :prop="fields.mandant.name"
            :required="fields.mandant.required"
          >
            <app-mandant-autocomplete-input
              :fetchFn="fields.mandant.fetchFn"
              :mapperFn="fields.mandant.mapperFn"
              mode="single"
              v-model="model[fields.mandant.name]"
              :disabled="!hasPermissionToChangeMandant"
            ></app-mandant-autocomplete-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item
            :label="fields.vertragsart.label"
            :prop="fields.vertragsart.name"
            :required="fields.vertragsart.required"
          >
            <app-vertragsart-autocomplete-input
              :fetchFn="fields.vertragsart.fetchFn"
              :mapperFn="fields.vertragsart.mapperFn"
              mode="single"
              v-model="model[fields.vertragsart.name]"
            ></app-vertragsart-autocomplete-input>
          </el-form-item>
        </el-col>
        <!--   <el-col :span="3" :offset="4">
          <el-form-item
            label=" "
            :prop="fields.isFiktive.name"
            :required="fields.isFiktive.required"
          >
            <el-checkbox v-model="model[fields.isFiktive.name]">
              {{ fields.isFiktive.label }}
            </el-checkbox>
          </el-form-item>
        </el-col> -->
        <el-col :span="3" :offset="7">
          <el-form-item
            :label="fields.gesamtwochenstunden.label"
            :prop="fields.gesamtwochenstunden.name"
            :required="fields.gesamtwochenstunden.required"
          >
            <app-money-input
              v-model="model[fields.gesamtwochenstunden.name]"
              :disabled="true"
            ></app-money-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item
            :label="fields.gesamtbrutto.label"
            :prop="fields.gesamtbrutto.name"
            :required="fields.gesamtbrutto.required"
          >
            <app-money-input
              v-model="model[fields.gesamtbrutto.name]"
              :disabled="true"
            ></app-money-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item
            :label="fields.gesamtkosten.label"
            :prop="fields.gesamtkosten.name"
            :required="fields.gesamtkosten.required"
          >
            <app-money-input
              v-model="model[fields.gesamtkosten.name]"
              :disabled="true"
            ></app-money-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="20">
        <el-col :span="6">
          <el-form-item
            :label="fields.beruf.label"
            :prop="fields.beruf.name"
            :required="fields.beruf.required"
          >
            <app-beruf-autocomplete-input
              :fetchFn="fields.beruf.fetchFn"
              :mapperFn="fields.beruf.mapperFn"
              :showCreate="true"
              mode="single"
              v-model="model[fields.beruf.name]"
            ></app-beruf-autocomplete-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item
            :label="fields.eintrittsdatum.label"
            :prop="fields.eintrittsdatum.name"
            :required="fields.eintrittsdatum.required"
          >
            <el-date-picker
              :format="elementUiDateFormat"
              placeholder
              type="date"
              v-model="model[fields.eintrittsdatum.name]"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item
            :label="fields.austrittsdatum.label"
            :prop="fields.austrittsdatum.name"
            :required="fields.austrittsdatum.required"
          >
            <el-date-picker
              :format="elementUiDateFormat"
              placeholder
              type="date"
              v-model="model[fields.austrittsdatum.name]"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="3">
            <el-form-item
              :label="fields.dienstjahre.label"
              :prop="fields.dienstjahre.name"
              :required="fields.dienstjahre.required"
            >
              <el-input
                :readonly="true"
                v-model="model[fields.dienstjahre.name]"
                tabindex="-1"
                :disabled="true"
              />
            </el-form-item>
          </el-col>
        <el-col :span="1" :offset="3">
          <el-form-item
            label=" "
            :prop="fields.uBahn.name"
            :required="fields.uBahn.required"
          >
            <el-checkbox
              v-model="model[fields.uBahn.name]"
              @change="recalculateGesamt"
            >
              {{ fields.uBahn.label }}
            </el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="3" :offset="0">
          <el-form-item
            label=" "
            :prop="fields.kommunalsteuer.name"
            :required="fields.kommunalsteuer.required"
          >
            <el-checkbox
              v-model="model[fields.kommunalsteuer.name]"
              @change="recalculateGesamt"
            >
              {{ fields.kommunalsteuer.label }}
            </el-checkbox>
          </el-form-item>
        </el-col>

        <el-col :span="3" :offset="0">
          <el-form-item
            :label="fields.probeMonat.label"
            :prop="fields.probeMonat.name"
            :required="fields.probeMonat.required"
          >
            <el-select placeholder v-model="model[fields.probeMonat.name]">
              <el-option :value="undefined">--</el-option>
              <el-option
                :key="option.id"
                :label="option.label"
                :value="option.id"
                v-for="option in fields.probeMonat.options"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item
            :label="fields.gehaltProJahr.label"
            :prop="fields.gehaltProJahr.name"
            :required="fields.gehaltProJahr.required"
          >
            <el-select placeholder v-model="model[fields.gehaltProJahr.name]">
              <el-option :value="undefined">--</el-option>
              <el-option
                :key="option.id"
                :label="option.label"
                :value="option.id"
                v-for="option in fields.gehaltProJahr.options"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div style="padding-top: 10px">
          <el-card shadow="mever" :body-style="{ padding: '10px' }">
            <app-grundgehalt-component
              ref="grundgehalt"
              :record="grundgehalt"
              :anstellung="id"
              :modal="modal"
              :gesamtbrutto="model[fields.gesamtbrutto.name]"
              :gesamtkosten="model[fields.gesamtkosten.name]"
              @woStdChange="model[fields.gesamtwochenstunden.name] = $event"
              :hasPermissionToDeleteKostenstelle="
                hasPermissionToDeleteKostenstelle
              "
              :hasPermissionToEditKostenstelle="hasPermissionToEditKostenstelle"
              :hasPermissionToCreateKostenstelle="
                hasPermissionToCreateKostenstelle
              "
              :isDisabled="isDisabledInternal"
            ></app-grundgehalt-component>
          </el-card>
        </div>
      </el-row>
      <el-row>
        <div style="padding-top: 10px">
          <el-card shadow="mever" :body-style="{ padding: '10px' }">
            <app-gehaltszulage-component
              ref="gehaltszulage"
              :record="gehaltszulage"
              :anstellung="id"
              :modal="modal"
              :gesamtbrutto="model[fields.gesamtbrutto.name]"
              :gesamtkosten="model[fields.gesamtkosten.name]"
              :hasPermissionToDeleteKostenstelle="
                hasPermissionToDeleteKostenstelle
              "
              :hasPermissionToEditKostenstelle="hasPermissionToEditKostenstelle"
              :hasPermissionToCreateKostenstelle="
                hasPermissionToCreateKostenstelle
              "
              :isDisabled="isDisabledInternal"
            ></app-gehaltszulage-component>
          </el-card>
        </div>
      </el-row>
      <div>
        <el-row>
          <div style="padding-top: 10px; padding-bottom: 60px">
            <el-card shadow="mever" :body-style="{ padding: '10px' }">
              <el-row :gutter="20">
                <el-col :sm="6" :md="5" :lg="3" :xl="2">
                  <div style="padding-top: 40px">
                    <el-button
                      style="width: 130px"
                      :disabled="saveLoading"
                      @click="$emit('validateForm', doOpenSchwangerschaftModal)"
                      :type="
                        model[fields.inMutterschutz.name] ? 'success' : 'info'
                      "
                      plain
                    >
                      Mutterschutz
                      <!--  <app-i18n code="common.save"></app-i18n> -->
                    </el-button>
                  </div>
                </el-col>

                <el-col :sm="1" :md="1" :lg="1" :xl="1">
                  <el-form-item
                    :prop="fields.inMutterschutz.name"
                    :required="fields.inMutterschutz.required"
                  >
                    <div style="padding-top: 40px">
                      <el-button
                        v-if="hasPermissionToRemoveKarenz"
                        :disabled="isMutterschutzSet"
                        type="danger"
                        icon="el-icon-close"
                        plain
                        @click="doAskForConfirmation(doRemoveMutterschutz)"
                      ></el-button>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.geplantenGeburtstermin.label"
                    :required="false"
                  >
                    <el-date-picker
                      :readonly="true"
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.geplantenGeburtstermin.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.mutterschutzBeginn.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.mutterschutzBeginn.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.mutterschutzEnde.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.mutterschutzEnde.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :sm="6" :md="5" :lg="3" :xl="2">
                  <div style="padding-top: 40px">
                    <el-button
                      style="width: 130px"
                      :disabled="saveLoading"
                      @click="$emit('validateForm', doOpenMutterkarenzModal)"
                      :type="
                        model[fields.inMutterkarenz.name] ? 'success' : 'info'
                      "
                      plain
                    >
                      Mutterkarenz
                      <!--  -->
                    </el-button>
                  </div>
                </el-col>
                <el-col :sm="1" :md="1" :lg="1" :xl="1">
                  <el-form-item
                    :prop="fields.inMutterkarenz.name"
                    :required="fields.inMutterkarenz.required"
                  >
                    <div style="padding-top: 40px">
                      <el-button
                        v-if="hasPermissionToRemoveKarenz"
                        :disabled="isMutterkarenzSet"
                        type="danger"
                        icon="el-icon-close"
                        plain
                        @click="doAskForConfirmation(doRemoveMutterkarenz)"
                      ></el-button>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.mutterkarenzBeginn.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.mutterkarenzBeginn.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.mutterkarenzEnde.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.mutterkarenzEnde.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :sm="6" :md="5" :lg="3" :xl="2">
                  <div style="padding-top: 40px">
                    <el-button
                      style="width: 130px"
                      :disabled="saveLoading"
                      @click="$emit('validateForm', doOpenPapamonatModal)"
                      :type="
                        model[fields.inPapamonat.name] ? 'success' : 'info'
                      "
                      plain
                    >
                      Papamonat
                      <!--  -->
                    </el-button>
                  </div>
                </el-col>
                <el-col :sm="1" :md="1" :lg="1" :xl="1">
                  <el-form-item
                    :prop="fields.inPapamonat.name"
                    :required="fields.inPapamonat.required"
                  >
                    <div style="padding-top: 40px">
                      <el-button
                        v-if="hasPermissionToRemoveKarenz"
                        :disabled="isPapamonatSet"
                        type="danger"
                        icon="el-icon-close"
                        plain
                        @click="doAskForConfirmation(doRemovePapamonat)"
                      ></el-button>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.papamonatBeginn.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.papamonatBeginn.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.papamonatEnde.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.papamonatEnde.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :sm="6" :md="5" :lg="3" :xl="2">
                  <div style="padding-top: 40px">
                    <el-button
                      style="width: 130px"
                      :disabled="saveLoading"
                      @click="$emit('validateForm', doOpenBildungskarenzModal)"
                      :type="
                        model[fields.inBildungskarenz.name] ? 'success' : 'info'
                      "
                      plain
                    >
                      Bildungskarenz
                      <!--  -->
                    </el-button>
                  </div>
                </el-col>
                <el-col :sm="1" :md="1" :lg="1" :xl="1">
                  <el-form-item
                    :prop="fields.inBildungskarenz.name"
                    :required="fields.inBildungskarenz.required"
                  >
                    <div style="padding-top: 40px">
                      <el-button
                        v-if="hasPermissionToRemoveKarenz"
                        :disabled="isBildungskarenzSet"
                        type="danger"
                        icon="el-icon-close"
                        plain
                        @click="doAskForConfirmation(doRemoveBildungskarenz)"
                      ></el-button>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.bildungskarenzBeginn.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.bildungskarenzBeginn.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :sm="6" :md="6" :lg="4" :xl="3">
                  <el-form-item
                    :label="fields.bildungskarenzEnde.label"
                    :required="false"
                  >
                    <el-date-picker
                      readonly
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.bildungskarenzEnde.name]"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :sm="6" :md="5" :lg="3" :xl="2">
                  <div style="padding-top: 40px">
                    <el-button
                      style="width: 130px"
                      :disabled="saveLoading"
                      @click="
                        $emit('validateForm', doOpenKarenzvertretungModal)
                      "
                      type="info"
                      plain
                    >
                      Karenzvertretung
                      <!--  <app-i18n code="common.save"></app-i18n> -->
                    </el-button>
                  </div>
                </el-col>
                <el-col :sm="10" :md="8" :lg="6" :xl="5" :offset="1">
                  <el-form-item
                    :label="fields.karenzvertretungMitarbeiter.label"
                    :prop="fields.karenzvertretungMitarbeiter.name"
                    :required="fields.karenzvertretungMitarbeiter.required"
                  >
                    <app-mitarbeiter-autocomplete-input
                      disabled
                      :fetchFn="fields.karenzvertretungMitarbeiter.fetchFn"
                      :mapperFn="fields.karenzvertretungMitarbeiter.mapperFn"
                      mode="single"
                      v-model="model[fields.karenzvertretungMitarbeiter.name]"
                    ></app-mitarbeiter-autocomplete-input>
                  </el-form-item>
                </el-col>
                <el-col :sm="10" :md="8" :lg="6" :xl="6">
                  <el-form-item
                    :label="fields.karenzvertretungFuerMitarbeiter.label"
                    :prop="fields.karenzvertretungFuerMitarbeiter.name"
                    :required="fields.karenzvertretungFuerMitarbeiter.required"
                  >
                    <app-mitarbeiter-autocomplete-input
                      disabled
                      :fetchFn="fields.karenzvertretungFuerMitarbeiter.fetchFn"
                      :mapperFn="
                        fields.karenzvertretungFuerMitarbeiter.mapperFn
                      "
                      mode="single"
                      v-model="
                        model[fields.karenzvertretungFuerMitarbeiter.name]
                      "
                    ></app-mitarbeiter-autocomplete-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </el-row>
      </div>
    </el-form>
    <div>
      <app-mitarbeiter-anstellung-mutterschutz-form-modal
        :visible="mutterschutzDialogVisible"
        @close="mutterschutzDialogVisible = false"
        @doSetMutterschutz="doSetMutterschutz"
        @doUpdateMutterschutz="doUpdateMutterschutz"
        v-if="mutterschutzDialogVisible"
        :anstellung="record"
      ></app-mitarbeiter-anstellung-mutterschutz-form-modal>
      <app-mitarbeiter-anstellung-mutterkarenz-form-modal
        :visible="mutterkarenzDialogVisible"
        @close="mutterkarenzDialogVisible = false"
        @doSetMutterkarenz="doSetMutterkarenz"
        @doUpdateMutterkarenz="doUpdateMutterkarenz"
        v-if="mutterkarenzDialogVisible"
        :anstellung="record"
      ></app-mitarbeiter-anstellung-mutterkarenz-form-modal>
      <app-mitarbeiter-anstellung-bildungskarenz-form-modal
        :visible="bildungskarenzDialogVisible"
        @close="bildungskarenzDialogVisible = false"
        @doSetBildungskarenz="doSetBildungskarenz"
        @doUpdateBildungskarenz="doUpdateBildungskarenz"
        v-if="bildungskarenzDialogVisible"
        :anstellung="record"
      ></app-mitarbeiter-anstellung-bildungskarenz-form-modal>
      <app-mitarbeiter-anstellung-papamonat-form-modal
        :visible="papamonatDialogVisible"
        @close="papamonatDialogVisible = false"
        @doSetPapamonat="doSetPapamonat"
        @doUpdatePapamonat="doUpdatePapamonat"
        v-if="papamonatDialogVisible"
        :anstellung="record"
      ></app-mitarbeiter-anstellung-papamonat-form-modal>
    </div>
    <div>
      <app-mitarbeiter-anstellung-karenzvertretung-form-modal
        :visible="karenzvertretungDialogVisible"
        @close="karenzvertretungDialogVisible = false"
        @doSubmitKarenzvertretung="doSubmitKarenzvertretung"
        v-if="karenzvertretungDialogVisible"
        :anstellung="record"
      ></app-mitarbeiter-anstellung-karenzvertretung-form-modal>
    </div>
  </div>
</template>
<script>
import { i18n } from '@/i18n';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { MitarbeiterAnstellungModel } from '@/modules/mitarbeiter/mitarbeiter-anstellung-model';
import { MitarbeiterPermissions } from '@/modules/mitarbeiter/mitarbeiter-permissions';
import GrundgehaltComponent from '@/modules/grundgehalt/components/grundgehalt-list.vue';
import GehaltszulageComponent from '@/modules/gehaltszulage/components/gehaltszulage-list.vue';
import AnstellungSchwangerschafBestaetigungModal from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-mutterschutz-form-modal.vue';
import AnstellungMutterkarenzModal from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-mutterkarenz-form-modal.vue';
import AnstellungBildungskarenzModal from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-bildungskarenz-form-modal.vue';
import AnstellungPapamonatModal from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-papamonat-form-modal.vue';
import AnstellungKarenzvertretungModal from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-karenzvertretung-form-modal.vue';
import CalculationHelper from '@/shared/helpers/calculationHelper';
/* import iban from 'iban'; */
const { fields } = MitarbeiterAnstellungModel;
const formSchema = new FormSchema([
  fields.id,
  fields.mitarbeiter,
  fields.mandant,
  fields.probeMonat,
  fields.vertragsart,
  fields.beruf,
  fields.eintrittsdatum,
  fields.austrittsdatum,
  fields.dienstjahre,
  fields.gehaltProJahr,
  fields.gesamtbrutto,
  fields.gesamtkosten,
  fields.gesamtwochenstunden,
  fields.uBahn,
  fields.kommunalsteuer,
  fields.karenzvertretungMitarbeiter,
  fields.karenzvertretungFuerMitarbeiter,
  fields.inMutterschutz,
  fields.inMutterkarenz,
  fields.geplantenGeburtstermin,
  fields.mutterschutzBeginn,
  fields.mutterschutzEnde,
  fields.mutterkarenzBeginn,
  fields.mutterkarenzEnde,
  fields.inBildungskarenz,
  fields.bildungskarenzBeginn,
  fields.bildungskarenzEnde,
  fields.inPapamonat,
  fields.papamonatBeginn,
  fields.papamonatEnde,
  fields.isActive,
  fields.isChange,
  fields.isHistory,
]);
export default {
  name: 'app-mitarbeiter-anstellung-form',
  props: ['record', 'formValidationSuccess', 'modal', 'saveLoading'],
  components: {
    [GrundgehaltComponent.name]: GrundgehaltComponent,
    [AnstellungSchwangerschafBestaetigungModal.name]:
      AnstellungSchwangerschafBestaetigungModal,
    [AnstellungMutterkarenzModal.name]: AnstellungMutterkarenzModal,
    [AnstellungBildungskarenzModal.name]: AnstellungBildungskarenzModal,
    [AnstellungPapamonatModal.name]: AnstellungPapamonatModal,
    [AnstellungKarenzvertretungModal.name]: AnstellungKarenzvertretungModal,
    [GehaltszulageComponent.name]: GehaltszulageComponent,
  },
  data() {
    return {
      rules: formSchema.rules(),
      model: {},
      grundgehalt: [],
      gehaltszulage: [],
      isLoaded: false,
      mutterschutzDialogVisible: false,
      karenzvertretungDialogVisible: false,
      mutterkarenzDialogVisible: false,
      bildungskarenzDialogVisible: false,
      papamonatDialogVisible: false,
      gesamtkosten: 0,
    };
  },
  async created() {
    if (!this.modal) {
      this.doReset();
    }
    this.model = formSchema.initialValues(this.record || {});
    this.grundgehalt = this.record.grundgehalt;
    this.gehaltszulage = this.record.gehaltszulage;
    if (!this.modal) {
      this.doSetAnstellung(this.model);
    }
  },
  mounted() {
    this.isLoaded = true;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      anstellung: 'mitarbeiter/form/anstellung',
      isFormDisabled: 'mitarbeiter/form/isFormDisabled',
      viewMode: 'mitarbeiter/form/viewMode',
      age: 'mitarbeiter/form/age',
      eintrittsdatum: 'mitarbeiter/form/eintrittsdatum',
      gesamtbruttoGrundgehalt: 'mitarbeiter/anstellung/gesamtbruttoGrundgehalt',
      gesamtbruttoGehaltszulage:
        'mitarbeiter/anstellung/gesamtbruttoGehaltszulage',
      recalculateGesamtkosten: 'mitarbeiter/anstellung/recalculateGesamtkosten',
      reActivationStarted: 'mitarbeiter/form/reActivationStarted',
      svWerteActive: 'settings/svWerteActive',
    }),
    fields() {
      return fields;
    },
    recalculateGesamtkostenChanged() {
      return this.recalculateGesamtkosten;
    },
    id() {
      return this.record.id;
    },
    hasPermissionToRemoveKarenz() {
      return new MitarbeiterPermissions(this.currentUser).removeKarenz;
    },
    hasPermissionToChangeMandant() {
      return new MitarbeiterPermissions(this.currentUser).destroy;
    },
    hasPermissionToCreateKostenstelle() {
      return new MitarbeiterPermissions(this.currentUser).createKostenstelle;
    },
    hasPermissionToEditKostenstelle() {
      return new MitarbeiterPermissions(this.currentUser).editKostenstelle;
    },
    hasPermissionToDeleteKostenstelle() {
      return new MitarbeiterPermissions(this.currentUser).destroyKostenstelle;
    },
    isBildungskarenzSet() {
      return !(
        this.record.bildungskarenzBeginn && this.record.bildungskarenzEnde
      );
    },
    isPapamonatSet() {
      return !(this.record.papamonatBeginn && this.record.papamonatEnde);
    },
    isMutterschutzSet() {
      return !(this.record.mutterschutzBeginn && this.record.mutterschutzEnde);
    },
    isMutterkarenzSet() {
      return !(this.record.mutterkarenzBeginn && this.record.mutterkarenzEnde);
    },
    isDisabledInternal() {
      return this.isFormDisabled || !!this.modal;
    },
  },
  watch: {
    model: {
      deep: true,
      handler: async function () {
        if (this.isLoaded && !this.modal) {
          await this.$store.dispatch(
            'mitarbeiter/form/doSetAnstellung',
            formSchema.cast(this.model),
          );
        }
      },
    },
    'model.vertragsart': {
      handler: function (newVal, oldVal) {
        if (this.isLoaded && !this.modal) {
          if (newVal && newVal.label.toLowerCase() === 'befristet') {
            this.rules.austrittsdatum.push({
              message: 'Austrittsdatum ist ein Pflichtfeld',
              required: true,
            });
          } else if (oldVal && oldVal.label.toLowerCase() === 'befristet') {
            this.rules.austrittsdatum = [];
          }
          //commented out as austrittsdatum is removed/not visible even when the austrittsdatum is set
          /* else if (newVal && newVal.label.toLowerCase() === 'unbefristet') {
            this.rules.austrittsdatum = [];

            this.$refs.form.fields
              .find((f) => f.prop == 'austrittsdatum')
              .resetField();
            this.model.austrittsdatum = null;
          } */
        }
      },
    },
    recalculateGesamtkostenChanged: {
      handler: function (value) {
        if (this.isLoaded && !this.modal) {
          if (value) {
            this.recalculateGesamt();
          }
        }
      },
    },
    gesamtbrutto() {
      return this.gesamtbruttoGrundgehalt + this.gesamtbruttoGehaltszulage;
    },
    reActivationStarted: {
      handler: function (value) {
        if (value) {
          this.model.eintrittsdatum = null;
          this.model.austrittsdatum = null;
          this.model.vertragsart = null;
          this.model.probeMonat = null;
          this.model.gehaltProJahr = null;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      doReset: 'mitarbeiter/anstellung/doReset',
      doSetAnstellung: 'mitarbeiter/anstellung/doSetAnstellung',
      doResetRecalculateSet: 'mitarbeiter/anstellung/doResetRecalculateSet',
    }),
    async recalculateGesamt() {
      if (this.gesamtbruttoGrundgehalt === null) {
        await this.$refs.grundgehalt.recalculateGesamtbrutto();
      }
      if (this.gesamtbruttoGehaltszulage === null) {
        await this.$refs.gehaltszulage.recalculateGesamtbrutto();
      }

      const gesamtbrutto =
        this.gesamtbruttoGrundgehalt + this.gesamtbruttoGehaltszulage;
      const gesamtkosten = new CalculationHelper(
        this.svWerteActive,
      ).gesamtkosten(
        gesamtbrutto,
        this.age,
        this.eintrittsdatum,
        this.model.uBahn,
        this.model.kommunalsteuer,
        this.model.gesamtwochenstunden,
      );
      this.model.gesamtbrutto = gesamtbrutto;
      this.model.gesamtkosten = gesamtkosten;
      this.doResetRecalculateSet();
    },
    doCancel() {
      this.$emit('cancel');
    },
    async validateForm() {
      // eslint-disable-next-line no-useless-catch
      try {
        await this.$refs.grundgehalt.$refs.form.validate();
        await this.$refs.gehaltszulage.$refs.form.validate();
        await this.$refs.form.validate();
      } catch (error) {
        await this.$refs.gehaltszulage.$refs.form.validate();
        await this.$refs.form.validate();
        throw error;
      }
    },

    async doAskForConfirmation(func) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return func();
      } catch (error) {
        // no
      }
    },

    doSetMutterschutz(payload) {
      this.setMutterschutzModel(payload);
      this.$emit('doSetMutterschutz', payload.karenz);
    },

    doUpdateMutterschutz(payload) {
      this.setMutterschutzModel(payload);
      this.$emit('doUpdateMutterschutz', payload.karenz);
    },
    doRemoveMutterschutz() {
      this.$emit('doRemoveMutterschutz');
    },
    setMutterschutzModel(payload) {
      this.model.geplantenGeburtstermin = payload.geplantenGeburtstermin;
      this.model.mutterschutzBeginn = payload.mutterschutzBeginn;
      this.model.mutterschutzEnde = payload.mutterschutzEnde;
      this.model.schutzfrist = payload.schutzfrist;
      this.mutterschutzDialogVisible = false;
    },

    doSetMutterkarenz(payload) {
      this.doSetMutterkarenzModel(payload);
      this.$emit('doSetMutterkarenz', payload.karenz);
    },

    doUpdateMutterkarenz(payload) {
      this.doSetMutterkarenzModel(payload);
      this.$emit('doUpdateMutterkarenz', payload.karenz);
    },

    doRemoveMutterkarenz() {
      this.$emit('doRemoveMutterkarenz');
    },

    doSetMutterkarenzModel(payload) {
      this.model.mutterkarenzBeginn = payload.mutterkarenzBeginn;
      this.model.mutterkarenzEnde = payload.mutterkarenzEnde;
      this.mutterkarenzDialogVisible = false;
    },

    doSetBildungskarenz(payload) {
      this.doSetBildungskarenzModel(payload);
      this.$emit('doSetBildungskarenz', payload.karenz);
    },

    doUpdateBildungskarenz(payload) {
      this.doSetBildungskarenzModel(payload);
      this.$emit('doUpdateBildungskarenz', payload.karenz);
    },
    doRemoveBildungskarenz() {
      this.$emit('doRemoveBildungskarenz');
    },
    doSetBildungskarenzModel(payload) {
      this.model.bildungskarenzBeginn = payload.bildungskarenzBeginn;
      this.model.bildungskarenzEnde = payload.bildungskarenzEnde;
      this.bildungskarenzDialogVisible = false;
    },

    doSetPapamonat(payload) {
      this.doSetPapamonatModel(payload);
      this.$emit('doSetPapamonat', payload.karenz);
    },
    doUpdatePapamonat(payload) {
      this.doSetPapamonatModel(payload);
      this.$emit('doUpdatePapamonat', payload.karenz);
    },
    doRemovePapamonat() {
      this.$emit('doRemovePapamonat');
    },
    doSetPapamonatModel(payload) {
      this.model.papamonatBeginn = payload.papamonatBeginn;
      this.model.papamonatEnde = payload.papamonatEnde;
      this.papamonatDialogVisible = false;
    },

    doSubmitKarenzvertretung(payload) {
      this.model.karenzvertretungMitarbeiter =
        payload.karenzvertretungMitarbeiter;
      this.model.karenzvertretungFuerMitarbeiter =
        payload.karenzvertretungFuerMitarbeiter;
      this.karenzvertretungDialogVisible = false;
      this.$emit('doSubmitKarenzvertretung');
    },
    doOpenSchwangerschaftModal() {
      this.mutterschutzDialogVisible = true;
    },
    doOpenMutterkarenzModal() {
      this.mutterkarenzDialogVisible = true;
    },
    doOpenBildungskarenzModal() {
      this.bildungskarenzDialogVisible = true;
    },
    doOpenPapamonatModal() {
      this.papamonatDialogVisible = true;
    },
    doOpenKarenzvertretungModal() {
      this.karenzvertretungDialogVisible = true;
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
}
</style>
